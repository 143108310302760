import "react-toastify/dist/ReactToastify.css";
import webfox from "./initialize";

const logError = (error) => {
  console.log("system error: ", error);
};

webfox.interceptors.request.use(
    async (config) => {
      const token = "fpfsm";
      const rest = "/v2/users/welcome";
      const phNumber = sessionStorage.getItem("contactNumber");
      config.headers!.customercode = token;
      config.headers!.rest = rest;
      config.headers!.contactNumber = phNumber as any;
      return config;
    },
    (error) => Promise.reject(error)
  );

  const exec = async (fn, params = null, error = null) => {
    const res = {
      data: null,
      error: null,
      syserror: null,
    };

    await fn(params)
      .then((response) => {
        res.data = response;
      })
      .catch((err) => {
        if (err.response) {
          console.log("err.response" + JSON.stringify(err.response));
          res.error = err.response;
        } else if (err.request) {
          console.log("err.request" + JSON.stringify(err.request));
          res.error = err.request;
        } else {
          console.log("err" + err);
          res.error = err;
        }
      });

    return res;
  };

  const AuthenticateMobile = (params) =>
    exec(() => {
      return webfox.post("/service.php", {
        contactNumber: params.contactNumber,
        rest: "/v2/users/welcome",
        module: "user",
      });
    });

export default {
  AuthenticateMobile,
  logError
};
