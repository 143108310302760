export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        login: {
          ...initialState.login,
          loading: true,
        },
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        login: {
          ...initialState.login,
          companycode: action.payload.companycode,
          uid: action.payload.uid,
          loading: false,
          isAuthenticated: true,
        },
      };
    case "LOGOUT":
      return {
        ...initialState,
        login: {
          ...initialState.login,
          Companycode: "",
          uid: "",
          isAuthenticated: false,
        },
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        login: {
          ...initialState.login,
          loading: false,
        },
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
