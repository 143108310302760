import PropTypes from 'prop-types';
import React from 'react';

export const LayoutAnonymous: React.FC<{}> = (props) => {
  return <div>{props.children}</div>;
};

LayoutAnonymous.propTypes = {
  children: PropTypes.node,
};
