import "./App.css";
import { Switch } from "react-router-dom";
import { Router, Route} from "react-router-dom";

import { AuthProvider } from "./services/authContext";
import { history } from "./configureStore";
import { PrivateRoutingOptions, PublicRoutingOptions } from "./common/routes";
import { LayoutAuthenticated } from "./Routes/LayoutPrivate";
import { PrivateRoute } from "./Routes/PrivateRoute";
import { PublicRoute } from "./Routes/PublicRoute";
import { privateRoutes, publicRoutes } from "./Routes/Routes";
import { LayoutAnonymous } from "./Routes/LayoutPublic";

function App() {
  return (
    <>
      <AuthProvider>
        <Router history={history}>
          <Switch>
            <Route exact path={PrivateRoutingOptions}>
                <LayoutAuthenticated>
                  <Switch>
                    {privateRoutes.map((privateRouteProps) => (
                      <PrivateRoute
                        {...privateRouteProps}
                        key={privateRouteProps.path}
                      />
                    ))}
                  </Switch>
                </LayoutAuthenticated>
            </Route>
            <Route exact path={PublicRoutingOptions}>
              <LayoutAnonymous>
                <Switch>
                  {publicRoutes.map((publicRouteProps) => (
                    <PublicRoute
                      {...publicRouteProps}
                      key={publicRouteProps.path}
                    />
                  ))}
                </Switch>
              </LayoutAnonymous>
            </Route>
          </Switch>
        </Router>
      </AuthProvider>
      </>
  );
}

export default App;
