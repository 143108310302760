import React from "react";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { Provider } from 'react-redux';
import store from './redux/store'
import configureStore from "./configureStore";
import ThemeProvider from "./themes/ThemeProvider";
import ErrorBoundary from "./services/logAnalytics";
import { StoreProvider } from "./services/webstore";
import { WebfoxProvider } from "../src/services/webfox";

const { persistor } = configureStore();

ReactDOM.render(
  <React.Fragment>
    <ErrorBoundary name={"StoreProvider"}>
      <StoreProvider>
        <WebfoxProvider>
          <PersistGate loading={<h2>Loading...</h2>} persistor={persistor}>
            <ErrorBoundary name={"App"}>
              <ThemeProvider>
                <Provider store = {store}>
                <App />
                 </Provider>
              </ThemeProvider>
            </ErrorBoundary>
          </PersistGate>
        </WebfoxProvider>
      </StoreProvider>
     
    </ErrorBoundary>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
