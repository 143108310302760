
export function isLoggedIn() {
  return true;
}
export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

export function getAllowedRoutes(sideBarList, accessControl) {
  let newArray = [] as any;
  sideBarList.forEach((item) => {
    accessControl.forEach((accessItem) => {
      if (accessItem.module === item.module) {
        newArray.push(item);
      }
    })
  });
  return newArray;
}

export function VerifyAccess(routes, accessControl) {
  const { path, ...restProps } = routes;
  let accessGranted = false;
  const pathName = path.slice(1);
  accessControl.forEach((item) => {
    if (item.module === pathName) {
      accessGranted = true;
    } else if(pathName === "jobCard"){
      accessGranted = true;
    }
  });
  return accessGranted;
}

export function DeleteClickHandle(moduleName: any) {

  const list = sessionStorage.getItem("ROLE_PRIVILAGES");
  const privilages = list !== null && JSON.parse(list);
  const privilagesList = privilages.role_privilege;
  const module = privilagesList.find((e) => e.module === moduleName);
  const btnHandleArray = [] as any;
  if (module !== undefined) {
    module.privilege.includes('create') ? btnHandleArray.push("create") : btnHandleArray.push(null);
    module.privilege.includes('update') ? btnHandleArray.push("update") : btnHandleArray.push(null);
    module.privilege.includes('delete') ? btnHandleArray.push("delete") : btnHandleArray.push(null);
    return btnHandleArray;
  }
}
