import actions from "./actions";

const Reducers = (state, action) => {
  switch (action.type) {
    case actions.OTP_SENT_REQUEST: {
      return {
        ...state,
        OtpInfo: {
          ...state.OtpInfo,
          loading: true,
        },
      };
    }
    case actions.OTP_SENT_SUCCESS: {
      return {
        ...state,
        OtpInfo: {
          ...state.OtpInfo,
          role_privilege: action.payload.role_privilege,
          user_details:action.payload.role_privilege,
          loading: false,
        },
      };
    }
    case actions.OTP_SENT_FAILURE: {
      return {
        ...state,
        OtpInfo: {
          ...state.OtpInfo,
          role_privilege: [],
          user_details:[],
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default Reducers;
