export const PublicRoutingOptions = [
  "/",
  "/login",
  "/GetOtp",
  "/calender",
  "/workOrderCalender",
  "/restricted",
  "/testComponent",
  "/sequence",
  "/fileUploadTestComponent",
  "/userCalendar",
];

export const PrivateRoutingOptions = [
  "/AttendanceDashboard",
  "/dashboard",
  "/office",
  "/addOffice",
  "/geoFence",
  "/users",
  "/addUser",
  "/roles",
  "/addRole",
  "/billDesk",
  "/editAccount",
  "/transactionHistory",
  "/taxGst",
  "/fpIntegration",
  "/skillsList",
  "/workOrderTypes",
  "/coverageArea",
  "/privileges",
  "/merchantStore",
  "/merchantItems",
  "/geoFence",
  "/addMerchantStore",
  "/editMerchantStore",
  "/addMerchantItems",
  "/editMerchantItems",
  "/merchantUsers",
  "/addMerchantUser",
  "/updateUser",
  "/storeItem",
  "/editUser",
  "/editRole",
  "/editOffice",
  "/divison",
  "/usersAttendance",
  "/attendanceDashboard",
  "/polygonDraw",
  "/workOrder",
  "/addWorkOrder",
  "/customer",
  "/addCustomer",
  "/editCustomer",
  "/serviceDashboard",
  "/dealers",
  "/addDealer",
  "/editDealer",
  "/dealerUser",
  "/addDealerUser",
  "/editDealerUser",
  "/purchaseOrder",
  "/addPurchaseOrder",
  "/editPurchaseOrder",
  "/dealerPurchaseOrder",
  "/addDealerPurchaseOrder",
  "/editWO",
  "/dealerInventory",
  "/itemList",
  "/wareHouse",
  "/addWareHouse",
  "/editWareHouse",
  "/addItemMaster",
  "/editItemMaster",
  "/consolidatedReport",
  "/config",
  "/customerProduct",
  "/surveyVersions",
  "/addVersions",
  "/editVersion",
  "/surveyReports",
  "/editQuestion",
  "/merchantOrders",
  "/trackOrder",
  "/reportAnswer",
  "/mapView",
  "/previewVersion",
  "/question",
  "/devices",
  "/addDevices",
  "/editDevice",
  "/workOderscheduler",
  "/IntelliSD",
  "/addMasterItems",
  "/editMasterItems",
  "/surveyRolloutManagement",
  "/houseHolds",
  "/reports",
  "/addReports",
  "/viewReports",
  "/jobCard"
];
