import { Link } from "react-router-dom";
import { Theme, makeStyles } from "@material-ui/core";
export const NotFound =() =>{
    const classes = useStyles();
    return (
        <div id="wrapper" className={classes.alignDiv}>
            <div id="info">
                <h3>Access restricted to this page. </h3>
                <h3>Please Redirect to <Link to="/">Login Page</Link></h3>
            </div>
        </div >
    )
}
const useStyles = makeStyles((theme: Theme) => ({
    alignDiv:{
        alignItem:"center",
        marginLeft:"320px",
        marginTop:"60px",
        height:"100%"
    }
}));