import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

//import { useAuthState } from '../services/authContext';

interface Props extends RouteProps {
  component
}

export const PublicRoute: React.FC<Props> = (props) => {
  //const userDetails = useAuthState();
  //const { login } = userDetails;
  //const isAuthenticated = userDetails.isAuthenticated;
  //const isAuthenticated = false;

  const { component: Component, ...restProps } = props;

  if (!Component) return null;

  return (
    <Route
      {...restProps}
      render={(routeRenderProps) =>
        // !login.isAuthenticated ? (
        true ? (
          <Component {...routeRenderProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/AttendanceDashboard',
              state: { from: routeRenderProps.location },
            }}
          />
        )
      }
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.any,
};
