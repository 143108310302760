import React, { useState, Suspense, useContext } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import { useTranslation } from "react-i18next";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TranslateIcon from "@material-ui/icons/Translate";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { ThemeContext } from "../../themes/ThemeProvider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useHistory } from "react-router-dom";

const ITEM_HEIGHT = 48;

const options = [
  {
    value: "english",
    label: "English",
  },
  {
    value: "hindi",
    label: "Hindi",
  },
];

const DashboardNavbar = ({
  onMobileNavOpen,
  sideNavToggle,
  setSideNavToggle,
  openMobile,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [notifications] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = React.useState("English");
  const { i18n } = useTranslation();
  const history = useHistory();
  const setThemeName = useContext(ThemeContext);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const handleLanguage = (event) => {
    setSelectedLanguage(event.target.innerText);
    if (event.target.innerText === "English") {
      i18n.changeLanguage("en");
    } else if (event.target.innerText === "Hindi") {
      i18n.changeLanguage("de");
    }
  };

  const handleDrawerOpen = () => {
    setSideNavToggle(!sideNavToggle);
  };

  const curThemeName = sessionStorage.getItem("appTheme");
  const [applyTheme, setApplyTheme] = useState(
    curThemeName === "lightTheme" ? "Light" : "Dark"
  );
  const setTheme = () => {
    if (curThemeName === "lightTheme") {
      setApplyTheme("Dark");
      setThemeName("darkTheme");
    } else {
      setApplyTheme("Light");
      setThemeName("lightTheme");
    }
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AppBar elevation={0} {...rest}>
        <Toolbar>
          <Hidden mdDown>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              style={{ marginLeft: sideNavToggle ? "15rem" : "9rem" }}
            >
              {sideNavToggle ? <MenuIcon /> : <MenuOpenIcon />}
            </IconButton>
          </Hidden>

          <Box sx={{ flexGrow: 2 }} />
          <FormControlLabel
            control={<Switch onChange={() => setTheme()} name="checkedB" />}
            label={<Hidden mdDown>{applyTheme}</Hidden>}
          />
          <div>
            <div>
              <IconButton
                style={{ color: "white" }}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <TranslateIcon />
                <Typography variant="h6">{selectedLanguage}</Typography>
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {options.map((option, index) => (
                  <MenuItem
                    value={option.value}
                    key={index}
                    onClick={handleLanguage}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {options.map((option, index) => (
                <MenuItem
                  value={option.value}
                  key={index}
                  onClick={handleLanguage}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
          {/* <Hidden mdDown> */}
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() => {
              history.push("../login");
            }}
          >
            <InputIcon />
          </IconButton>
          {/* </Hidden> */}
          {/* <Hidden mdUp> */}
          <Hidden only={["lg", "xl"]}>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    </Suspense>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
