import Auth from "./routes";

export function loginUser(dispatch, loginPayload) {
  const params = loginPayload;
  let loginData;
  sessionStorage.removeItem("currentUser");

  const returnData = Auth.AuthenticateMobile(params).then(({ data, error }) => {
    dispatch({ type: "REQUEST_LOGIN", data: [] });
    if (error) {
      console.log("errrorr", error);
      dispatch({ type: "LOGIN_ERROR", error: error });
      return error;
    } else {
      if (data) {
        if (data.success) {
          loginData = {
            companycode: "" || data.companycode,
            uid: "" || data.uid,
            loading: false,
            isAuthenticated: true,
          };
        } else {
          dispatch({ type: "LOGIN_ERROR", error: error });
        }
      } else {
        loginData = {
          loading: false,
          isAuthenticated: false,
        };
      }
      // dispatch({ type: "LOGIN_SUCCESS", payload: loginData });
      sessionStorage.setItem("currentUser", JSON.stringify(loginData));
      return loginData;
    }
  });
  return returnData;
}
