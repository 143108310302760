import "react-toastify/dist/ReactToastify.css";
import webfox from "./initialize";

const logError = (error) => {
  console.log("system error: ", error);
};

webfox.interceptors.request.use(
  async (config) => {
    // const userJson = localStorage.getItem('currentUser');
    // const companycode = userJson !== null ? JSON.parse(userJson).companycode : '';
    // const token = companycode;
    const companycode = sessionStorage.getItem("COMPANY_CODE");
    const token = sessionStorage.getItem("BEARER_TOKEN");
    config.headers!.Authorization = 'Bearer' + ' ' + token;
    config.headers!.customercode = companycode as any;
    return config;
  },
  (error) => Promise.reject(error)
);

const exec = async (fn, params = null, error = null) => {
  const res = {
    data: null,
    error: null,
    syserror: null,
  };

  await fn(params)
    .then((response) => {
      res.data = response;
    })
    .catch((err) => {
      if (err.response) {
        console.log("err.response" + JSON.stringify(err.response));
        res.error = err.response;
      } else if (err.request) {
        console.log("err.request" + JSON.stringify(err.request));
        res.error = err.request;
      } else {
        console.log("err" + err);
        res.error = err;
      }
    });

  return res;
};

const verifyOtp = (params) =>
  exec(() => {
    return webfox.post("/service.php", {
      uid: params.uid,
      otp: params.verificationCode,
      rest: "/v2/users/auth",
      module: "user",
    });
  });

const listData = (params) =>
  exec(() => {
    return webfox.get(
      `/service.php?rest=${params.rest}&module=${params.module}`
    );
  });


const addNewData = (params) =>
  exec(() => {
    return webfox.post("/service.php", params);
  });

const editData = (params) =>
  exec(() => {
    return webfox.put("/service.php", params);
  });

const deleteData = (params) =>
  exec(() => {
    return webfox.delete(
      `/service.php?rest=${params.rest}&module=${params.module}`
    );
  });

const listPrivileges = () =>
  exec(() => {
    return webfox.get(
      `/service.php?rest=/v2/privilege&module=user`
    );
  });
const getRolePrivilages = (params) =>
  exec(() => {
    return webfox.get(
      `/service.php?rest=/v2/roles/${params}&module=user`
    );
  });
const getWorkOrderData = (params) =>
  exec(() => {
    return webfox.get(
      `/service.php?rest=/v2/technicianworkorder/${params}&module=workorder`
    );
  });
const createTicket = (params) =>
  exec(() => {
    return webfox.post("/service.php", params);
  });

const updateGeofence = (params) =>
  exec(() => {
    return webfox.patch("/service.php", params);
  });
const gpMapLog = (params) =>
  exec(() => {
    return webfox.post("/service.php", params);
  });
const getUsersData = (params) => 
  exec(() => {
    return webfox.post("/service.php", params);
  });
export default {
  verifyOtp,
  logError,
  listData,
  addNewData,
  editData,
  deleteData,
  listPrivileges,
  getRolePrivilages,
  createTicket,
  updateGeofence,
  gpMapLog,
  getWorkOrderData,
  getUsersData
};
