import React from "react";
import Loadable from "react-loadable";

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <div></div>,
  });

export const publicRoutes = [
  {
    key: "login",
    path: "/login",
    component: loadable(() => import("../Screens/Login")),
    exact: true,
  },
  {
    key: "getOtp",
    path: "/getOtp",
    component: loadable(() => import("../Screens/OtpScreen")),
    exact: true,
  },
  {
    key: "login",
    path: "/",
    component: loadable(() => import("../Screens/Login")),
    exact: true,
  },
  {
    key: "calender",
    path: "/calender",
    component: loadable(() => import("../components/Calender")),
    exact: true,
  },
  {
    path: "/workOrderCalender",
    component: loadable(() => import("../Screens/Workorder/WO_Calender")),
    exact: true,
  },
  {
    path: "/restricted",
    component: loadable(() => import("../components/Restriced")),
  },
  {
    path: "/testComponent",
    component: loadable(() => import("../Screens/Workorder/TestComponent")),
    exact: true,
  },
  {
    path: "/testComponent",
    component: loadable(() => import("../Screens/Workorder/TestComponent")),
    exact: true,
  },
  {
    path: "/sequence",
    component: loadable(
      () => import("../Screens/Survey/SurveyVersions/QuestionSequence")
    ),
  },
  {
    path: "/fileUploadTestComponent",
    component: loadable(
      () => import("../Screens/TestComponent")
    ),
  },
  {
    path: "/userCalendar",
    component: loadable(
      () => import("../Screens/UserCalendar")
    ),
  },
];

export const privateRoutes = [
  {
    path: "/dashboard",
    component: loadable(() => import("../Screens/Dashboard")),
    title: "Dashboard",
  },
  {
    path: "/office",
    component: loadable(() => import("../Screens/Office/Office")),
    title: "Office",
  },
  {
    path: "/addOffice",
    component: loadable(() => import("../Screens/Office/AddOffice")),
    title: "AddOffice",
  },
  {
    path: "/geoFence",
    component: loadable(() => import("../Screens/Office/GeoFence")),
    title: "GeoFence",
  },
  {
    path: "/coverageArea",
    component: loadable(() => import("../Screens/Office/CoverageArea")),
    title: "coverageArea",
  },
  {
    path: "/roles",
    component: loadable(() => import("../Screens/Roles/Roles")),
    title: "RolesDetails",
  },
  {
    path: "/addRole",
    component: loadable(() => import("../Screens/Roles/AddRole")),
    title: "AddRole",
  },
  {
    path: "/privileges",
    component: loadable(() => import("../Screens/Roles/Privileges")),
    title: "Privileges",
  },
  {
    path: "/users",
    component: loadable(() => import("../Screens/Users/Users")),
    title: "Users",
  },
  {
    path: "/addUser",
    component: loadable(() => import("../Screens/Users/AddUser")),
    title: "AddUser",
  },
  {
    path: "/billDesk",
    component: loadable(() => import("../Screens/Account/BillDesk")),
    title: "BillDesk",
  },
  {
    path: "/editAccount",
    component: loadable(() => import("../Screens/Account/EditAccount")),
    title: "EditAccount",
  },
  {
    path: "/taxGst",
    component: loadable(() => import("../Screens/Account/Tax&GstDetails")),
    title: "TaxGst",
  },
  {
    path: "/transactionHistory",
    component: loadable(() => import("../Screens/Account/TransactionHistory")),
    title: "TransactionHistory",
  },
  {
    path: "/fpIntegration",
    component: loadable(() => import("../Screens/FPintegration")),
    title: "FpIntegration",
  },
  {
    path: "/skillsList",
    component: loadable(() => import("../Screens/SkillsList")),
    title: "SkillsList",
  },
  {
    path: "/workOrderTypes",
    component: loadable(() => import("../Screens/WorkOrderTypes")),
    title: "WorkOrder",
  },
  {
    path: "/merchantStore",
    component: loadable(() => import("../Screens/Merchant/MerchantStore")),
    title: "MerchantStore",
  },
  {
    path: "/merchantItems",
    component: loadable(() => import("../Screens/Merchant/MerchantItems")),
    title: "merchantItems",
  },
  {
    path: "/addMerchantStore",
    component: loadable(() => import("../Screens/Merchant/AddMerchantStore")),
    title: "MerchantPanel",
  },
  {
    path: "/editMerchantStore",
    component: loadable(() => import("../Screens/Merchant/EditMerchantStore")),
    title: "MerchantPanel",
  },
  {
    path: "/geoFence",
    component: loadable(() => import("../Screens/Merchant/GeoFence")),
    title: "GeoFence1",
  },
  {
    path: "/addMerchantItems",
    component: loadable(() => import("../Screens/Merchant/AddMerchantItems")),
    title: "AddItems",
  },
  {
    path: "/editMerchantItems",
    component: loadable(() => import("../Screens/Merchant/EditMerchantItems")),
    title: "AddItems",
  },
  {
    path: "/merchantUsers",
    component: loadable(() => import("../Screens/Merchant/MerchantUser")),
    title: "MerchantUser",
  },
  {
    path: "/addMerchantUser",
    component: loadable(() => import("../Screens/Merchant/AddMerchantUser")),
    title: "AddNewUser",
  },
  {
    path: "/updateUser",
    component: loadable(() => import("../Screens/Merchant/EditMerchantUser")),
    title: "UpdateMerchantUser",
  },
  {
    path: "/storeItem",
    component: loadable(() => import("../Screens/StoreItem/StoreItem")),
    title: "storeItem",
  },
  {
    path: "/purchaseOrder",
    component: loadable(
      () => import("../Screens/storeManagement/PurchaseOrder")
    ),
  },
  {
    path: "/addPurchaseOrder",
    component: loadable(
      () => import("../Screens/storeManagement/AddPurchaseOrder")
    ),
  },
  {
    path: "/editUser",
    component: loadable(() => import("../Screens/Users/EditUser")),
  },
  {
    path: "/editRole",
    component: loadable(() => import("../Screens/Roles/EditRole")),
  },
  {
    path: "/editOffice",
    component: loadable(() => import("../Screens/Office/EditOffice")),
  },
  {
    path: "/attendanceDashboard",
    component: loadable(() => import("../Screens/AttendanceDashboard")),
  },
  {
    path: "/divison",
    component: loadable(() => import("../Screens/Divison")),
  },
  {
    path: "/usersAttendance",
    component: loadable(() => import("../Screens/UsersAttendance")),
  },
  {
    path: "/polygonDraw",
    component: loadable(() => import("../Screens/Office/PolygonDraw")),
  },
  {
    path: "/workOrder",
    component: loadable(() => import("../Screens/Workorder/WorkOrder")),
  },
  {
    path: "/addWorkOrder",
    component: loadable(() => import("../Screens/Workorder/AddWorkorder")),
  },
  {
    path: "/customer",
    component: loadable(() => import("../Screens/Customer/Customers")),
  },
  {
    path: "/addCustomer",
    component: loadable(() => import("../Screens/Customer/AddCustomer")),
  },
  {
    path: "/editCustomer",
    component: loadable(() => import("../Screens/Customer/EditCustomer")),
  },
  {
    path: "/serviceDashboard",
    component: loadable(() => import("../Screens/DealerDashboard")),
  },
  {
    path: "/dealers",
    component: loadable(
      () => import("../Screens/DealerManagement/Dealers/Dealer")
    ),
  },
  {
    path: "/addDealer",
    component: loadable(
      () => import("../Screens/DealerManagement/Dealers/AddDealer")
    ),
  },
  {
    path: "/editDealer",
    component: loadable(
      () => import("../Screens/DealerManagement/Dealers/editDealer")
    ),
  },
  {
    path: "/dealerUser",
    component: loadable(
      () => import("../Screens/DealerManagement/DealerUsers/DealerUser")
    ),
  },
  {
    path: "/addDealerUser",
    component: loadable(
      () => import("../Screens/DealerManagement/DealerUsers/AddDealerUser")
    ),
  },
  {
    path: "/editDealerUser",
    component: loadable(
      () => import("../Screens/DealerManagement/DealerUsers/EditDealerUser")
    ),
  },
  {
    path: "/dealerPurchaseOrder",
    component: loadable(
      () =>
        import("../Screens/DealerManagement/PurchaseOrder/DealerPurchaseOrder")
    ),
  },
  {
    path: "/addDealerPurchaseOrder",
    component: loadable(
      () =>
        import(
          "../Screens/DealerManagement/PurchaseOrder/AddDealerPurchaseOrder"
        )
    ),
  },
  {
    path: "/editWO",
    component: loadable(() => import("../Screens/Workorder/EditWO")),
  },
  {
    path: "/dealerInventory",
    component: loadable(() => import("../Screens/Inventory/Inventory")),
  },
  {
    path: "/itemList",
    component: loadable(() => import("../Screens/ItemList/ItemList")),
  },
  {
    path: "/wareHouse",
    component: loadable(() => import("../Screens/WareHouse/WareHouse")),
  },
  {
    path: "/addItemMaster",
    component: loadable(() => import("../Screens/ItemList/AddItem")),
  },
  {
    path: "/editItemMaster",
    component: loadable(() => import("../Screens/ItemList/EditItemMaster")),
  },
  {
    path: "/addWareHouse",
    component: loadable(() => import("../Screens/WareHouse/AddWareHouse")),
  },
  {
    path: "/editWareHouse",
    component: loadable(() => import("../Screens/WareHouse/EditWareHouse")),
  },
  {
    path: "/consolidatedReport",
    component: loadable(() => import("../Screens/ConsolidatedReport")),
  },
  {
    path: "/config",
    component: loadable(() => import("../Screens/Config_Parameter")),
  },
  {
    path: "/customerProduct",
    component: loadable(
      () => import("../Screens/CustomerProduct/CustomerProduct")
    ),
  },
  {
    path: "/surveyVersions",
    component: loadable(
      () => import("../Screens/Survey/SurveyVersions/SurveyVersions")
    ),
  },
  {
    path: "/reportAnswer",
    component: loadable(
      () => import("../Screens/Survey/SurveyVersions/ReportAnswer")
    ),
  },
  {
    path: "/addVersions",
    component: loadable(
      () => import("../Screens/Survey/SurveyVersions/AddVersions")
    ),
  },
  {
    path: "/editVersion",
    component: loadable(
      () => import("../Screens/Survey/SurveyVersions/EditVersion")
    ),
  },
  {
    path: "/surveyReports",
    component: loadable(
      () => import("../Screens/Survey/SurveyVersions/SurveyReports")
    ),
  },
  {
    path: "/surveyRolloutManagement",
    component:loadable(()=>import("../Screens/Survey/SurveyVersions/SurveyManagement"))
  },
  {
    path: "/houseHolds",
    component:loadable(()=>import("../Screens/HouseHolds/HouseHolds"))
  },
  {
    path: "/reports",
    component:loadable(()=>import("../Screens/Reports/Reports"))
  },
  {
    path: "/addReports",
    component:loadable(()=>import("../Screens/Reports/AddReports"))
  },
  {
    path: "/viewReports",
    component: loadable(
      () => import("../Screens/Reports/ViewReports")
    ),
  },
  {
    path: "/editQuestion",
    component: loadable(
      () => import("../Screens/Survey/SurveyVersions/EditQuestion")
    ),
  },
  {
    path: "/merchantOrders",
    component: loadable(() => import("../Screens/Orders/MerchantOrders")),
  },
  {
    path: "/trackOrder",
    component: loadable(() => import("../Screens/Orders/TrackOrder")),
  },
  {
    path: "/mapView",
    component: loadable(() => import("../Screens/Workorder/MapView")),
  },
  {
    path: "/previewVersion",
    component: loadable(
      () => import("../Screens/Survey/SurveyVersions/Preview")
    ),
  },
  {
    path: "/question",
    component: loadable(
      () => import("../Screens/Survey/SurveyVersions/Questions")
    ),
  },
  {
    path: "/devices",
    component: loadable(() => import("../Screens/Devices/Devices")),
  },
  {
    path: "/addDevices",
    component: loadable(() => import("../Screens/Devices/AddDevices")),
  },
  {
    path: "/editDevice",
    component: loadable(() => import("../Screens/Devices/EditDevice")),
  },
  {
    path: "/workOderscheduler",
    component: loadable(() => import("../Screens/Workorder/WO_Scheduler")),
    exact: true,
  },
  {
    path: "/IntelliSD",
    component: loadable(() => import("../Screens/Workorder/IntelliSD")),
    exact: true,
  },
  {
    path: "/addMasterItems",
    component: loadable(() => import("../Screens/Workorder/AddMasterItems")),
    exact: true,
  },
  {
    path: "/editMasterItems",
    component: loadable(() => import("../Screens/Workorder/EditMasterItems")),
    exact: true,
  },
  {
    path: "/jobCard",
    component: loadable(() => import("../Screens/JobCard/JobCard")),
    exact: true,
  },
];
