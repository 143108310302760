const userJson = sessionStorage.getItem("currentUser");
const companycode = userJson !== null ? JSON.parse(userJson).companycode : "";
const userId = userJson !== null ? JSON.parse(userJson).uid : "";
const isAuthenticated = userJson !== null ? JSON.parse(userJson).success : "";

const initialState = {
  login: {
    companycode: "" || companycode,
    uid: "" || userId,
    loading: false,
    isAuthenticated: false || isAuthenticated,
  },
};
export default initialState;
