import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    CompanyCode: '',
    rolePrivilages: [],
}

export const userSlice = createSlice({
    name: "details",
    initialState,
    reducers: {
        setCompanyCode: (state, action) => {
            state.CompanyCode = action.payload;
        },
        setRolePrivilages: (state, action) => {
            state.rolePrivilages = action.payload;
        }
    }
});

export const { setCompanyCode, setRolePrivilages } = userSlice.actions;
