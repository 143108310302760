import axios from 'axios';
import   httpClient from "../../utils/apiConfig";

const webfoxConfig: any = {
  headers: {
    'Content-Type': 'application/json'
  },
     baseURL:httpClient.baseURL,
  timeout: 60000,
  responseType: 'json',
};

export default axios.create(webfoxConfig);