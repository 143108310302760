import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Theme, makeStyles, Grid } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BusinessIcon from "@material-ui/icons/Business";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router-dom";
import StorefrontIcon from "@material-ui/icons/Storefront";
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import ShopTwoOutlinedIcon from "@material-ui/icons/ShopTwoOutlined";
import {
  formatedOrganisationList,
  configurationList,
  merchant,
  dealerManagement,
  support,
} from "../../common/sideNavList";
import { getAllowedRoutes } from "../../utils";
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
} from "@material-ui/core";
import PermDataSettingIcon from "@material-ui/icons/PermDataSetting";
import { useTranslation } from "react-i18next";
import karnatakastatehea from "../../image/StateHealth.jpg";
import PubertIndia from "../../image/PubertIndia.jpg";
import Giffy from "../../image/giffy.jpg";
import asmpro from "../../image/asmpro.jpg";
import RolePrivilagesMap from "../../Routes/RolePrivilageMap";
import TBGhana from "../../image/tbGhana.jpg";
import SRE from "../../image/SRE-LOGO.jpg";

export const DashboardSidebar = ({
  onMobileClose,
  openMobile,
  sideNavToggle,
}) => {
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const [organisationMenu, setOrganisationMenu] = useState(false);
  const [configurationMenu, setConfigurationMenu] = useState(false);
  const [storeManagementMenu, setStoreManagementMenu] = useState(false);
  const [dealerManagementMenu, setDealerManagementMenu] = useState(false);
  const [supportMenu, setSupportMenu] = useState(false);
  const [menuHighlight, setMenuHighlight] = useState({} as any);
  const [companyName, setCompanyName] = useState("");
  const [dashboardText, setDashboardText] = useState("");
  const [dashboardPath, setDashboardPath] = useState("");

  // AromaCaffe pubert karnatakastatehea

  useEffect(() => {
    const currentUser = sessionStorage.getItem("currentUser");
    const companyCode =
      currentUser !== null ? JSON.parse(currentUser).companycode : "";
    setCompanyName(companyCode);
  }, []);

  const accessControler = RolePrivilagesMap();

  useEffect(() => {
    if (
      accessControler.find((e) => e.module === "serviceDashboard") !== undefined
    ) {
      setDashboardPath("serviceDashboard");
      setDashboardText("Service Dashboard");
    } else if (
      accessControler.find((e) => e.module === "attendanceDashboard") !==
      undefined
    ) {
      setDashboardPath("attendanceDashboard");
      setDashboardText("Attendance Dashboard");
    } else if (
      accessControler.find((e) => e.module === "dashboard") !== undefined
    ) {
      setDashboardPath("dashboard");
      setDashboardText("Dashboard");
    }
  }, []);
  const organisationList = formatedOrganisationList();

  const accessedOrgansationList = getAllowedRoutes(
    organisationList,
    accessControler
  );

  const accessedConfigList = getAllowedRoutes(
    configurationList,
    accessControler
  );
  const accessedMechantList = getAllowedRoutes(merchant, accessControler);
  const accessedSupportList = getAllowedRoutes(support, accessControler);
  const accessedDealerMagmntList = getAllowedRoutes(
    dealerManagement,
    accessControler
  );

  const onClickOrganisation = (obj) => {
    setMenuHighlight(obj.key);
    history.push(`/${obj.value}`);
  };
  const handleClickOrganisationMenu = () => {
    setOrganisationMenu(!organisationMenu);
  };
  const handleClickConfigurationMenu = () => {
    setConfigurationMenu(!configurationMenu);
  };
  const handleClickStoreManagementMenu = () => {
    setStoreManagementMenu(!storeManagementMenu);
  };
  const handleClickDealerManagementMenu = () => {
    setDealerManagementMenu(!dealerManagementMenu);
  };
  const onClickConfiguaration = (obj) => {
    setMenuHighlight(obj.key);
    history.push(`/${obj.value}`);
  };
  const onClickMerchant = (obj) => {
    setMenuHighlight(obj.key);
    history.push(`/${obj.value}`);
  };
  const onClickDealerManagement = (obj) => {
    setMenuHighlight(obj.key);
    history.push(`/${obj.value}`);
  };
  const handleClickSupportMenu = () => {
    setSupportMenu(!supportMenu);
  };
  const onClickSupport = (obj) => {
    setMenuHighlight(obj.key);
    history.push(`/${obj.value}`);
  };
  const dashBoardPath = () => {
    setMenuHighlight(100);
    history.push(`${dashboardPath}`);
  };
  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        // width: "234px",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        <img
          src={
            companyName === "giffy"
              ? Giffy
              : companyName === "pubert"
              ? PubertIndia
              : companyName === "asmpro"
              ? asmpro
              : companyName === 'tbghana'
                    ? TBGhana
                    : companyName === 'sre'
              ?SRE
              : karnatakastatehea
          }
          alt="logo"
          style={{
            cursor: "pointer",
            width: 100,
            height: 100,
          }}
        />
        {companyName !== "pubert" && (
          <>
            {companyName === "karnatakastatehea" && (
              <Typography
                color="textPrimary"
                variant="body1"
                className={sideNavToggle ? classes.show : classes.hide}
              >
                {"Department of"}
              </Typography>
            )}
            {companyName === "karnatakastatehea" && (
              <Typography
                color="textPrimary"
                variant="body1"
                className={sideNavToggle ? classes.show : classes.hide}
              >
                {"Health & Family Welfare"}
              </Typography>
            )}
            <Typography
              color="textPrimary"
              variant="h6"
              className={sideNavToggle ? classes.show : classes.hide}
            >
              {companyName === "karnatakastatehea"
                ? "Karnataka"
                : companyName === "asmpro"
                  ? "ASMPRO"
                  : companyName === "tbghana"
                    ? "TBGhana"
                    : companyName === 'sre'
              ?"SRE"
                : "Giffy Consumer"}
            </Typography>
          </>
        )}
      </Box>

      <Divider />
      <Box>
        {dashboardPath !== "" && dashboardText !== "" && (
          <List component="nav">
            <div>
              <ListItem button onClick={dashBoardPath}>
                <ListItemIcon
                  className={classes.mainIconAllign}
                  style={{
                    color: menuHighlight === 100 ? "#1976d2" : "black",
                  }}
                >
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${dashboardText}`}
                  className={sideNavToggle ? classes.show : classes.hide}
                  style={{
                    color: menuHighlight === 100 ? "#1976d2" : "black",
                  }}
                />
              </ListItem>
            </div>
          </List>
        )}

        {accessedOrgansationList.length !== 0 && (
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            // className={classes.listIconStyle}
          >
            <ListItem button onClick={handleClickOrganisationMenu}>
              <ListItemIcon className={classes.mainIconAllign}>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("Organisation")}
                className={sideNavToggle ? classes.show : classes.hide}
              />
              {organisationMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={organisationMenu}>
              <List
                component="div"
                disablePadding
                className={classes.listStyle}
              >
                {accessedOrgansationList.map((d) => (
                  <div
                    key={d.name}
                    onClick={() => {
                      onClickOrganisation(d);
                    }}
                    style={{
                      color: menuHighlight === d.key ? "#1976d2" : "black",
                      maxWidth:236,
                    }}
                  >
                    <ListItem button>
                      {d.icon && <d.icon className={classes.subIconAllign} />}
                      <ListItemText
                        primary={d.name}
                        className={sideNavToggle ? classes.show : classes.hide}
                      />
                    </ListItem>
                  </div>
                ))}
              </List>
            </Collapse>
          </List>
        )}

        {accessedConfigList.length !== 0 && (
          <List component="nav" aria-labelledby="nested-list-subheader">
            <ListItem button onClick={handleClickConfigurationMenu}>
              <ListItemIcon className={classes.mainIconAllign}>
                <PermDataSettingIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("Configuration")}
                className={sideNavToggle ? classes.show : classes.hide}
              />
              {configurationMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={configurationMenu} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                className={classes.listStyle}
              >
                {/* {configurationList.map((d) => ( */}
                {accessedConfigList.map((d) => (
                  <div
                    key={d.name}
                    onClick={() => {
                      onClickConfiguaration(d);
                    }}
                    style={{
                      color: menuHighlight === d.key ? "#1976d2" : "black",
                    }}
                  >
                    <ListItem button>
                      {d.icon && <d.icon className={classes.subIconAllign} />}
                      <ListItemText
                        primary={d.name}
                        className={sideNavToggle ? classes.show : classes.hide}
                      />
                    </ListItem>
                  </div>
                ))}
              </List>
            </Collapse>
          </List>
        )}

        {accessedMechantList.length !== 0 && (
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            // className={classes.listIconStyle}
          >
            <ListItem button onClick={handleClickStoreManagementMenu}>
              <ListItemIcon className={classes.mainIconAllign}>
                <StorefrontIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("StoreManagement")}
                className={sideNavToggle ? classes.show : classes.hide}
              />
              {storeManagementMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={storeManagementMenu} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                className={classes.listStyle}
              >
                {/* {merchant.map((d) => ( */}
                {accessedMechantList.map((d) => (
                  <div
                    key={d.name}
                    onClick={() => {
                      onClickMerchant(d);
                    }}
                    style={{
                      color: menuHighlight === d.key ? "#1976d2" : "black",
                    }}
                  >
                    <ListItem button>
                      {d.icon && <d.icon className={classes.subIconAllign} />}
                      <ListItemText
                        primary={d.name}
                        className={sideNavToggle ? classes.show : classes.hide}
                      />
                    </ListItem>
                  </div>
                ))}
              </List>
            </Collapse>
          </List>
        )}
        {accessedDealerMagmntList.length !== 0 && (
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            // className={classes.listIconStyle}
          >
            <ListItem button onClick={handleClickDealerManagementMenu}>
              <ListItemIcon className={classes.mainIconAllign}>
                <ShopTwoOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("Dealer Management")}
                className={sideNavToggle ? classes.show : classes.hide}
              />
              {dealerManagementMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={dealerManagementMenu} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                className={classes.listStyle}
              >
                {/* {dealerManagement.map((d) => ( */}
                {accessedDealerMagmntList.map((d) => (
                  <div
                    key={d.name}
                    onClick={() => {
                      onClickDealerManagement(d);
                    }}
                    style={{
                      color: menuHighlight === d.key ? "#1976d2" : "black",
                    }}
                  >
                    <ListItem button>
                      {d.icon && <d.icon className={classes.subIconAllign} />}
                      <ListItemText
                        primary={d.name}
                        className={sideNavToggle ? classes.show : classes.hide}
                      />
                    </ListItem>
                  </div>
                ))}
              </List>
            </Collapse>
          </List>
        )}

        {accessedSupportList.length !== 0 && (
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            // className={classes.listIconStyle}
          >
            <ListItem button onClick={handleClickSupportMenu}>
              <ListItemIcon className={classes.mainIconAllign}>
                <LiveHelpOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("Support")}
                className={sideNavToggle ? classes.show : classes.hide}
              />
              {supportMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={supportMenu} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                className={classes.listStyle}
              >
                {/* {support.map((d) => ( */}
                {accessedSupportList.map((d) => (
                  <div
                    key={d.name}
                    onClick={() => {
                      onClickSupport(d);
                    }}
                    style={{
                      color: menuHighlight === d.key ? "#1976d2" : "black",
                    }}
                  >
                    <ListItem button>
                      {d.icon && <d.icon className={classes.subIconAllign} />}
                      <ListItemText
                        primary={d.name}
                        className={sideNavToggle ? classes.show : classes.hide}
                      />
                    </ListItem>
                  </div>
                ))}
              </List>
            </Collapse>
          </List>
        )}
      </Box>
      {/* <Grid>
        <footer className={classes.versionStyle}>
          <Typography color="textSecondary" gutterBottom variant="h6" className={classes.vesrionTextStyle}>
            Version.4.0.2
          </Typography>
        </footer>
      </Grid> */}
    </Box>
  );

  return (
    <>
      <Hidden only={["lg", "lg"]}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          className={classes.upDrawer}
          //   PaperProps={{
          //     sx: {
          //       width: 256
          //     }
          //   }}
        >
          {content}
          <Box sx={{display:"flex",flexDirection:"row",alignContent:"center",ml:5}}>
          23.08#f465bd2
          </Box>
        </Drawer>
      </Hidden>
      <Hidden only={["sm", "xs", "md"]}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          className={
            sideNavToggle ? classes.downDrawerShow : classes.downDrawerHide
          }
          //   PaperProps={{
          //     sx: {
          //       width: 256,
          //       top: 64,
          //       height: 'calc(100% - 64px)'
          //     }
          //   }}
        >
          {content}
          <Box sx={{display:"flex",flexDirection:"row",ml:5}}>
          23.08#f465bd2
          </Box>
        </Drawer>
      </Hidden>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  upDrawer: {
    maxWidth: 200,
  },
  listStyle: { backgroundColor: "white" },
  downDrawerShow: {
    width: 234,
    height: "calc(100% - 64px)",
    marginTop: "66px",
  },
  downDrawerHide: {
    width: 133,
    height: "calc(100% - 64px)",
    marginTop: "66px",
  },
  hide: {
    display: "none",
  },
  show: {
    display: "flex",
    flex: "0 1 auto",
  },
  subIconAllign: {
    marginLeft: "25px",
    marginRight: "10px",
  },
  mainIconAllign: {
    minWidth: "30px",
  },
  listIconStyle: { paddingRight: "55px" },
  versionStyle: {
    marginLeft: "25px",
    position: "fixed",
    bottom: "0",
  },
  vesrionTextStyle:{
    fontWeight:"bold"
  }
}));

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  sideNavToggle: PropTypes.bool,
  setSideNavToggle: PropTypes.func,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
