import React, { useState } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import { getThemeByName } from "./base";

export const ThemeContext = React.createContext(
  (themeName: string): void => {}
);

const ThemeProvider: React.FC = (props) => {
  const curThemeName = sessionStorage.getItem("appTheme") || "lightTheme";
  const [themeName, _setThemeName] = useState(curThemeName);

  const theme = getThemeByName(themeName);

  const setThemeName = (themeName: string): void => {
    sessionStorage.setItem("appTheme", themeName);
    _setThemeName(themeName);
  };

  return (
    <ThemeContext.Provider value={setThemeName}>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
