import PropTypes from "prop-types";
import React, { useState } from "react";
import ErrorBoundary from "../services/logAnalytics";
import DashboardSidebar from "../components/DashBoard/DashboardSidebar";
import DashboardNavbar from "../components/DashBoard/DashboardNavbar";
import { Theme, makeStyles } from "@material-ui/core";

export const LayoutAuthenticated: React.FC<{}> = (props) => {
  const classes = useStyles();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [sideNavToggle, setSideNavToggle] = useState(true);

  return (
    <>
      <div className={classes.DashboardLayoutRoot}>
        <DashboardNavbar
          onMobileNavOpen={() => setMobileNavOpen(true)}
          openMobile={isMobileNavOpen}
          sideNavToggle={sideNavToggle}
          setSideNavToggle={setSideNavToggle}
        />
        <DashboardSidebar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
          sideNavToggle={sideNavToggle}
          setSideNavToggle={setSideNavToggle}
        />
        <div className={classes.DashboardLayoutWrapper}>
          <div className={classes.DashboardLayoutContainer}>
            <div className={classes.DashboardLayoutContent}>
              <ErrorBoundary name={"pages"}>{props.children}</ErrorBoundary>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  DashboardLayoutRoot: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  DashboardLayoutWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    '[theme.breakpoints.up("lg")]': {
      paddingLeft: 256,
    },
  },
  DashboardLayoutContainer: {
    // display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  DashboardLayoutContent: {
    flex: "1 1 auto",
    height: "100%",
    // overflow: "auto",
  },
}));

LayoutAuthenticated.propTypes = {
  children: PropTypes.node,
};
