import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { RoleBasedRouting } from "./RoleBasedRouting";

interface Props extends RouteProps {
  component;
}

export const PrivateRoute: React.FC<Props> = (props) => {

  const { component: Component, ...restProps } = props;
  const isAuthenticated = sessionStorage.getItem("Login_Sucess");

  if (!Component) return null;

  return (
    <>
      {isAuthenticated === "true" ? (
        <RoleBasedRouting {...props} />
      ) : (
        <Route
          {...restProps}
          render={(routeRenderProps) => (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: routeRenderProps.location },
              }}
            />
          )}
        />
      )}
    </>
  );
};
