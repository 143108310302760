import React from "react";
import {Route, RouteProps } from "react-router-dom";
import { VerifyAccess } from "../utils";
import { NotFound } from "../common/NotFound";
import RolePrivilagesMap from "./RolePrivilageMap"

interface Props extends RouteProps {
  component;
}

export const RoleBasedRouting: React.FC<Props> = (props) => {
  const { component: Component, ...restProps } = props;
  if (!Component) return null;

  const listData = RolePrivilagesMap();

  //const check = VerifyAccess(restProps, accessControl);
  const check = VerifyAccess(restProps, listData);

  return (
    <Route
      {...restProps}
      render={(routeRenderProps) =>
        check ? (
          <Component {...routeRenderProps} />
        ) : (
          <NotFound />
        )
      }
    />
  );
};
