import api from './queries';

const logerror = api.logError;

const AuthenticateMobile: any = async (params: any) => {
  const res = await api.AuthenticateMobile(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

 const routes = {
  AuthenticateMobile,
};
export default routes;
