import api from "./queries";
import loginApi from "./LoginQuerie";
import userApi from "./AddUserQueries";

const logerror = api.logError;

export const auth = () => { };
export const webfoxstore = () => { };

export const AuthenticateMobile: any = async (params: any) => {
  //const res = await api.AuthenticateMobile(params).catch((e) => {
  const res = await loginApi.AuthenticateMobile(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const verifyOtp: any = async (params: any) => {
  const res = await api.verifyOtp(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const ListData: any = async (params: any) => {
  const res = await api.listData(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const addNewData: any = async (params: any) => {
  const res = await api.addNewData(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const editData: any = async (params: any) => {
  const res = await api.editData(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};
export const deleteData: any = async (params: any) => {
  const res = await api.deleteData(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const deleteFPUser: any = async (params: any) => {
  console.log("routes=>deleteFPUser", "before calling AddUserQueries.deleteFPUser");

  const res = await userApi.deleteFPUser(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const listPrivileges: any = async () => {
  const res = await api.listPrivileges().catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getRolePrivilages: any = async (params: any) => {
  const res = await api.getRolePrivilages(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getWorkOrderData: any = async (params: any) => {
  const res = await api.getWorkOrderData(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const createTicket: any = async (params: any) => {
  const res = await api.createTicket(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};
export const updateGeofence: any = async (params: any) => {
  const res = await api.updateGeofence(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};
export const addNewUser: any = async (params: any) => {
  const res = await userApi.addNewUser(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};
export const getTicketList: any = async (params: any) => {
  const res = await userApi.getTicketList(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};
export const getAuthToken: any = async (params: any) => {
  const res = await userApi.getAuthToken(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};
export const gpMapLog: any = async (params: any) => {
  const res = await api.gpMapLog(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const UsersData: any = async (params: any) => {
  const res = await api.getUsersData(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};
export const uploadImage : any = async (params:any)=>{
  const res = await userApi.uploadImage(params).catch((e) => {
    logerror("routes error: " + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
}