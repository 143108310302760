import PrivilageList from "./Privilages.json";

const RolePrivilageMap = () => {
  const comapnyCode = sessionStorage.getItem("COMPANY_CODE");
  const list = sessionStorage.getItem("ROLE_PRIVILAGES");

  const privilages = list !== null && JSON.parse(list);
  const privilagesList = privilages.role_privilege;
  const listData = [
    {
      key: 65,
      module: "divison",
    },
    {
      key: 66,
      module: "usersAttendance",
    },
    {
      key: 67,
      module: "consolidatedReport",
    },
    {
      key: 53,
      module: "workOderscheduler"
    },
    {
      key: 71,
      module: "IntelliSD"
    },
    {
      key: 72,
      module: "JobCard"
    } as any,
  ];

  privilagesList.map((prev, index) => {
    if (
      PrivilageList[prev.module] !== undefined &&
      prev.module !== "menuhdr_dashboard"
    ) {
      if (prev.privilege.includes("view")) {
        const newPrev = {
          key: index + "-" + PrivilageList[prev.module][0],
          module: PrivilageList[prev.module][0],
        };
        listData.push(newPrev);
      }
      if (
        prev.privilege.includes("create") &&
        PrivilageList[prev.module][1] != null
      ) {
        const newPrev = {
          key: index + "-" + PrivilageList[prev.module][1],
          module: PrivilageList[prev.module][1],
        };
        listData.push(newPrev);
      }
      if (
        prev.privilege.includes("update") &&
        PrivilageList[prev.module][2] != null
      ) {
        const newPrev = {
          key: index + "-" + PrivilageList[prev.module][2],
          module: PrivilageList[prev.module][2],
        };
        listData.push(newPrev);
      }
    } else if (prev.module === "menuhdr_dashboard") {
      if (comapnyCode === "giffy") {
        const newPrev = {
          key: index + "-" + PrivilageList[prev.module][0],
          module: PrivilageList[prev.module][0],
        };
        listData.push(newPrev);
      } else if (comapnyCode === "pubert") {
        const newPrev = {
          key: index + "-" + PrivilageList[prev.module][1],
          module: PrivilageList[prev.module][1],
        };
        listData.push(newPrev);
      } else if (comapnyCode === "karnatakastatehea") {
        const newPrev = {
          key: index + "-" + PrivilageList[prev.module][2],
          module: PrivilageList[prev.module][2],
        };
        listData.push(newPrev);
      } else if (comapnyCode === "asmpro") {
        const newPrev = {
          key: index + "-" + PrivilageList[prev.module][2],
          module: PrivilageList[prev.module][2],
        };
        listData.push(newPrev);
      }
    }
  });
  return listData;
};

export default RolePrivilageMap;
