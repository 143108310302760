import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import DirectionsBikeOutlinedIcon from "@material-ui/icons/DirectionsBikeOutlined";
import LabelImportantOutlinedIcon from "@material-ui/icons/LabelImportantOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExtensionIcon from "@material-ui/icons/Extension";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import StoreIcon from "@material-ui/icons/Store";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import DevicesIcon from '@material-ui/icons/Devices';

export const formatedOrganisationList = () => {
  const constantList = sessionStorage.getItem("Label_Config_List");
  const Data = constantList !== null && JSON.parse(constantList);
  const headerText = Data.label_for_office;

  const list = [
    {
      name: headerText,
      key: 0,
      icon: BusinessIcon,
      value: "office",
      module: "office",
      privileges: ["view", "create"],
    },
    {
      name: "Users",
      key: 1,
      icon: PeopleAltRoundedIcon,
      value: "users",
      module: "users",
      privileges: ["view", "create"],
    },
    {
      name: "Roles",
      key: 2,
      icon: AssignmentIndIcon,
      value: "roles",
      module: "roles",
      privileges: ["view", "create"],
    },
    {
      name: "Item Master",
      key: 3,
      icon: AddShoppingCartIcon,
      value: "Item Master",
      module: "item-master",
      privileges: ["view", "create"],
    },
    {
      name: "Item Master",
      key: 3,
      icon: AddShoppingCartIcon,
      value: "itemList",
      module: "itemList",
      privileges: ["view", "create"],
    },
    {
      name: "Customer Product",
      key: 30,
      icon: StoreMallDirectoryIcon,
      value: "customerProduct",
      module: "customerProduct",
      privileges: ["view", "create"],
    },
    {
      name: "Warehouse",
      key: 4,
      icon: StoreMallDirectoryIcon,
      value: "WareHouse",
      module: "wareHouse",
      privileges: ["view", "create"],
    },
    {
      name: "Survey Versions",
      key: 43,
      icon: BusinessCenterOutlinedIcon,
      value: "surveyVersions",
      module: "surveyVersions",
      privileges: ["view", "create"],
    },
    {
      name: "Survey Rollout & Management",
      key: 50,
      icon:  ListAltIcon,
      value: "surveyRolloutManagement",
      module: "surveyRolloutManagement",
      privileges: ["view", "create"],
    },
    {
      name: "House Hold Survey",
      key: 51,
      icon:  ListAltIcon,
      value: "houseHolds",
      module: "houseHolds",
      privileges: ["view", "create"],
    },
    {
      name: "Reports",
      key: 59,
      icon:  ListAltIcon,
      value: "reports",
      module: "reports",
      privileges: ["view", "create"],
    },
    {
      name: "Devices",
      key: 44,
      icon: DevicesIcon,
      value: "devices",
      module: "devices",
      privileges: ["view", "create"],
    },
    
  ];
  return list;
};
export const configurationList = [
  {
    name: "FpIntegration",
    key: 5,
    icon: ExtensionIcon,
    value: "fpIntegration",
    module: "fpIntegration",
    privileges: ["view", "create"],
  },
  {
    name: "WO Types",
    key: 6,
    icon: AssignmentTurnedInIcon,
    value: "workOrderTypes",
    module: "workOrderTypes",
    privileges: ["view", "create"],
  },
  {
    // name: "Skills",
    name: "Product Category",
    key: 7,
    icon: AssignmentTurnedInIcon,
    value: "skillsList",
    module: "skillsList",
    privileges: ["view", "create"],
  },
  {
    name: "Config",
    key: 22,
    icon: AssignmentTurnedInIcon,
    value: "config",
    module: "config",
    privileges: ["view", "create"],
  },
];
export const merchant = [
  {
    name: "Store",
    key: 8,
    value: "merchantStore",
    icon: StoreIcon,
    module: "merchantStore",
    privileges: ["view", "create"],
  },
  {
    name: "Orders",
    key: 38,
    value: "merchantOrders",
    icon: ShoppingCartIcon,
    module: "merchantOrders",
    privileges: ["view", "create"],
  },
  {
    name: "Store Item",
    key: 9,
    value: "merchantItems",
    module: "merchantItems",
    icon: ListAltIcon,
    privileges: ["view", "create"],
  },
  {
    name: "MerchantUsers",
    key: 10,
    value: "merchantUsers",
    module: "merchantUsers",
    icon: AccountBoxIcon,
    privileges: ["view", "create"],
  },
  {
    name: "Promotions",
    key: 11,
    value: "promotions",
    module: "promotions",
    icon: AccountCircleIcon,
    privileges: ["view", "create"],
  },
  {
    name: "Inventory",
    key: 12,
    value: "inventory",
    module: "inventory",
    icon: DescriptionOutlinedIcon,
    privileges: ["view", "create"],
  },
  {
    name: "PO/STR",
    key: 13,
    value: "purchaseOrder-STR",
    module: "purchaseOrder-STR",
    icon: ShoppingCartOutlinedIcon,
    privileges: ["view", "create"],
  },
  {
    name: "Suppliers",
    key: 14,
    value: "suppliers",
    module: "suppliers",
    icon: DirectionsBikeOutlinedIcon,
    privileges: ["view", "create"],
  },
  {
    name: "Inward",
    key: 15,
    value: "inward",
    module: "inward",
    icon: LabelImportantOutlinedIcon,
    privileges: ["view", "create"],
  },
  {
    name: "Store Item List",
    key: 15,
    value: "storeItem",
    module: "storeItem",
    icon: LabelImportantOutlinedIcon,
    privileges: ["view", "create"],
  },
];
export const dealerManagement = [
  {
    name: "Dealers",
    key: 8,
    value: "dealers",
    module: "dealers",
    icon: StoreIcon,
    privileges: ["view", "create"],
  },
  {
    name: "Dealer User",
    key: 9,
    value: "dealerUser",
    module: "dealerUser",
    icon: ListAltIcon,
    privileges: ["view", "create"],
  },
  {
    name: "Purchase Order",
    key: 10,
    value: "dealerPurchaseOrder",
    module: "dealerPurchaseOrder",
    icon: AccountCircleIcon,
    privileges: ["view", "create"],
  },
  {
    name: "Inventory",
    key: 11,
    value: "dealerInventory",
    module: "dealerInventory",
    icon: DescriptionOutlinedIcon,
    privileges: ["view", "create"],
  },
];
export const support = [
  {
    name: "Tickets/Cases",
    key: 13,
    icon: BusinessCenterOutlinedIcon,
    value: "tickets/Cases",
    module: "tickets/Cases",
    privileges: ["view", "create"],
  },
  {
    name: "Online Order",
    key: 14,
    icon: ShoppingCartOutlinedIcon,
    value: "purchaseOrder",
    module: "purchaseOrder",
    privileges: ["view", "create"],
  },
  {
    name: "Tickets",
    key: 22,
    icon: ListAltIcon,
    value: "Workorder",
    module: "workOrder",
    privileges: ["view", "create"],
  },
  {
    name: "Customers",
    key: 21,
    icon: PeopleOutlinedIcon,
    value: "customer",
    module: "customer",
    privileges: ["view", "create"],
  },
  {
    name: "JobCard",
    key: 23,
    icon: ListAltIcon,
    value: "jobCard",
    module: "workOrder",
    privileges: ["view", "create"],
  },
];
