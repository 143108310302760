const light = {
    'theme': 'light',
    'backgroundColor': '#F3F3F3',
    'color': '#2A3139',
    'linkColor': 'rgb(188,75,45)',
    'accentColor': 'rgb(75,12,11)',
    'shadowColor': 'rgba(96,144,114,0.6)',
    button: {
        'backgroundColor': '#eceff1',
        'color': '#cfd2da',
        'marginTop': '2px',
        'height': "38px",
        'width': '38px',
    },
    div: {
        'background': '#fff',
        'color': '#2A3139',
        // 'padding': '20px',
        // 'height': '320px',
        'boxShadow': '0 2px 2px 0 rgba(0,0,0,.2), 0 2px 10px 0 rgba(0,0,0,.19)',
        'borderRadius': '4px'
    },
    table: {
        'color': '#2A3139'
    },
    commonSettings: {
        'background': '#eceff1',
        'color': '#2A3139 !important',
        'linkColor': 'rgb(188,75,45) !important',
        'accentColor': 'rgb(75,12,11) !important',
        'shadowColor': 'rgba(96,144,114,0.6) !important',
    },
    menuNav: {
        'background': '#f8f9fa',
    },
    menuNav2: {
        'backgroundColor': 'rgb(255, 255, 255)',
    },
    customDashboard: {
        'backgroundColor': 'whitesmoke',
        'minHeight': '500px',
        'backgroundImage': 'radial-gradient(#6c757d 20%, transparent 25%), radial-gradient(#6c757d 25%, transparent 25%)',
        'backgroundPosition': '0 0, 50px 50px',
        'backgroundSize': '5px 5px',
        'overflow': 'hidden'
    },
    card: {
        'color': '#2A3139',
        'boxShadow': '0 2px 2px 0 rgba(0,0,0,.2), 0 2px 10px 0 rgba(0,0,0,.19)',
        'borderRadius': '4px',
        'background': '#fff',
        "marginLeft": "1px"
    },
    tile: {
        'background': '#fff',
        'color': '#2A3139',
        'boxShadow': '0 2px 2px 0 rgba(0,0,0,.2), 0 2px 10px 0 rgba(0,0,0,.19)',
        'borderRadius': '4px',
    },
    modal: {
        'background': 'white',
        'color': '#2A3139',
    },
    input: {
        'color': '#495057',
    },
    popOver: {
        'background': '#fff',
        'color': '#2A3139',
        'borderRadius': '4px',
        'border': '1px solid #2125296b'
    },
    playBook: {
        'backgroundColor': 'whitesmoke'
    },
    divCard: {
        'background': '#fff',
        'color': '#2A3139',
        // 'padding': '20px',
        // 'height': '320px',
        'boxShadow': '0 2px 2px 0 rgba(0,0,0,.2), 0 2px 10px 0 rgba(0,0,0,.19)',
        'borderRadius': '4px'
    }
};

const dark = {
    'theme': 'dark',
    'backgroundColor': '#272b35',
    'color': '#cfd2da',
    'linkColor': 'rgb(188,75,45)',
    'accentColor': 'rgb(75,12,11)',
    'shadowColor': 'rgba(96,144,114,0.6)',
    button: {
        'backgroundColor': '#272b35',
        'color': '#cfd2da',
        'marginTop': '2px',
        'height': "38px",
        'width': '38px'
    },
    div: {
        'background': '#1a1e27',
        'color': '#cfd2da',
        'boxShadow': '0 2px 2px 0 rgba(0,0,0,.2), 0 2px 10px 0 rgba(0,0,0,.19)',
        // 'boxShadow': '0 2px 2px 0 #f8f9fa4f, 0 2px 10px 0 #f8f9fa4f',
        'borderRadius': '4px'
    },
    table: {
        'color': '#cfd2da'
    },
    commonSettings: {
        'background': '#272b35',
        'color': '#cfd2da !important',
        'linkColor': 'rgb(188,75,45) !important ',
        'accentColor': 'rgb(75,12,11) !important',
        'shadowColor': 'rgba(96,144,114,0.6) !important',
    },
    menuNav: {
        'background': '#272b35',
    },

    menuNav2: {
        'background': '#1a1e27',
    },
    customDashboard: {
        'backgroundColor': '#1a1e27',
        'minHeight': '500px',
        'backgroundImage': 'radial-gradient(#6c757d 20%, transparent 25%), radial-gradient(#6c757d 25%, transparent 25%)',
        'backgroundPosition': '0 0, 50px 50px',
        'backgroundSize': '5px 5px',
        'overflow': 'hidden'
    },
    card: {
        'color': '#2A3139',
        'boxShadow': '0 2px 2px 0 rgba(0,0,0,.2), 0 2px 10px 0 rgba(0,0,0,.19)',
        'borderRadius': '4px',
        'background': '#cfd2da',
        "marginLeft": "1px"
    },
    tile: {
        'background': '#1a1e27',
        'color': '#cfd2da',
        'boxShadow': '0 2px 2px 0 rgba(0,0,0,.2), 0 2px 10px 0 rgba(0,0,0,.19)',
        'borderRadius': '4px'
    },
    modal: {
        'background': '#343a40',
        'color': '#cfd2da',
    },
    input: {
        'background': '#171d21',
        'color': '#cfd2da',
        'borderColor': '#f8f9fa8c'
    },
    popOver: {
        'background': 'rgb(39, 43, 53)',
        'color': '#cfd2da',
        'borderRadius': '4px',
        'border': ' 1px solid gray'
    },
    playBook: {
        'backgroundColor': '#1a1e27'
    },
    divCard: {
        'background': '#272b35',
        'color': '#cfd2da',
        'boxShadow': 'rgba(0, 0, 0, 0.2) 0px 4px 2px 0px, rgb(217, 217, 247) 0px 0px 2px 0px',
        'borderRadius': '4px'
    }
};

export default function themeReducer(state = {}, action) {
    switch (action.type) {
        case "getDefaultTheme":
            return { theme: light }
        case "light":
            return { theme: light }
        case "dark":
            return { theme: dark }
    }
    return state
}
