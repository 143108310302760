import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import   httpClient from "../../utils/apiConfig";

let webfox;

const exec = async (fn, params = null, error = null) => {
  const res = {
    data: null,
    error: null,
    syserror: null,
  };

  await fn(params)
    .then((response) => {
      res.data = response;
    })
    .catch((err) => {
      if (err.response) {
        console.log("err.response" + JSON.stringify(err.response));
        res.error = err.response;
      } else if (err.request) {
        console.log("err.request" + JSON.stringify(err.request));
        res.error = err.request;
      } else {
        console.log("err" + err);
        res.error = err;
      }
    });

  return res;
};


 const URL= httpClient.baseURL;
// const uploadURL = "https://dev9.fieldprofsm.com/fp_gateway/";
 const uploadURL="https://dev9.fieldprofsm.com:8761";
// const uploadURL = "https://prd9.fieldprofsm.com:8761";

const deleteFPUser = (params) =>
  exec(() => {
    console.log("AddUserQueries=>deleteFPUser");
    webfox = axios.create({
      headers: {
        "Content-Type": "application/json",
        customercode: sessionStorage.getItem("COMPANY_CODE") as any,
        Authorization: "Bearer" + " " + sessionStorage.getItem("BEARER_TOKEN"),
        rest: "/v2/users",
        deletedphonenumber: params.deletedPhoneNumber,
        uid: params.uid,
      },
      baseURL: URL,
      timeout: 60000,
      responseType: "json",
    });
    return webfox.delete(
      `/service.php?rest=${params.rest}&module=${params.module}`
    );
  });

const addNewUser = (params) =>
  exec(() => {
    webfox = axios.create({
      headers: {
        "Content-Type": "application/json",
        customercode: sessionStorage.getItem("COMPANY_CODE") as any,
        Authorization: "Bearer" + " " + sessionStorage.getItem("BEARER_TOKEN"),
        rest: "/v2/users",
        contactNumber: params.contactNumber,
      },

      baseURL: URL,
      timeout: 60000,
      responseType: "json",
    });
    return webfox.post("/service.php", params);
  });

const getTicketList = (params) =>
  exec(() => {
    webfox = axios.create({
      headers: {
        "Content-Type": "application/json",
        customercode: sessionStorage.getItem("COMPANY_CODE") as any,
        contactNumber: params.contactNumber,
        isUser: true,
      },
      baseURL: URL,
      timeout: 60000,
      responseType: "json",
    });
    return webfox.get(
      `/service.php?rest=${params.rest}&module=${params.module}`
    );
  });
const getAuthToken = (params) =>
  exec(() => {
    webfox = axios.create({
      headers: {
        "Content-Type": "application/json",
        customercode: sessionStorage.getItem("COMPANY_CODE") as any,
      },
      baseURL: URL,
      timeout: 60000,
      responseType: "json",
    });
    return webfox.post("/service.php", params);
  });

const getUsersData = (params) =>
  exec(() => {
    webfox = axios.create({
      headers: {
        "Content-Type": "application/json",
        customercode: sessionStorage.getItem("COMPANY_CODE") as any,
      },
      baseURL: URL,
      timeout: 60000,
      responseType: "json",
    });
    return webfox.post("/service.php", params);
  });

// const uploadImage=(params)=> exec(() => {
//   console.log("paramsparams",params);

//   webfox = axios.create({
//     headers: {
//       "Content-Type": "multipart/form-data",
//       customercode: sessionStorage.getItem('COMPANY_CODE') as any,
//     },
//     baseURL: "http://fpdev.entriver.com:8761",
//     timeout: 60000,
//     responseType: "json",
//   });
//   return webfox.post("/v2/uploadFile", params);
// });

const uploadImage = (params) =>
  exec(() => {
    webfox = axios.create({
      baseURL: uploadURL,
      headers: {
        "content-Type": "multipart/form-data",
        customercode: "pubert",
        uid: 30
      },
    });
    return webfox.post("contentService.php", params);
  });

export default {
  addNewUser,
  getTicketList,
  getAuthToken,
  deleteFPUser,
  getUsersData,
  uploadImage,
};
